<template>
  <div class="reminder-popup" v-if="isReminderOpen">
    <div class="reminder-popup-content">
      <div class="reminder-popup-text text-left">
        <img
          :src="require(`@/assets/images/icons/ic-alert-triangle-gray.svg`)"
          alt="alert-triangle"
        />
        <h3>Before you proceed</h3>
        <p class="font-weight-thin">
          Being transparent and providing accurate information about your car's
          condition, damage, and features can help you get more bids on you car.
        </p>
        <p class="font-weight-thin mt-3">
          It also helps in avoiding misunderstandings or price renegotiations
          later in the selling process.
        </p>
      </div>
      <div class="reminder-popup-buttons">
        <button class="reminder-popup-next" @click="handleNext">
          My car details are accurate
        </button>
        <button class="reminder-popup-cancel" @click="handleCancel">
          Wait, I want to review my details
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReminderPopup",
  props: {
    isReminderOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    handleNext() {
      this.$router.push({ name: "navigation", query: this.$route.query });
    },
  },
};
</script>
<style lang="scss">
.reminder-popup {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: Avenir Next LT W05 Regular !important;
    font-weight: 600 !important;
    font-size: 18px;
    margin-top: 15px;
    line-height: 30px !important;
  }
  p {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
    width: 352px;
    color: #667085;
  }
  button {
    // margin: 0 10px;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  .reminder-popup-content {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    .reminder-popup-text {
      width: 90%;
    }
    .reminder-popup-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 29px;
      width: 352px;
      .reminder-popup-next {
        font-family: Avenir Next LT W05 Regular !important;
        font-weight: 600 !important;
        height: 44px;
        background-color: var(--primary-color);
        color: #fff;
        border: none;
        outline: none !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
      .reminder-popup-cancel {
        font-family: Avenir Next LT W05 Regular !important;
        color: black;
        font-weight: 600 !important;
        height: 44px;
        background-color: #ffffff;
        border: 1px solid var(--gray-300, #d0d5dd);
        outline: none !important;
        margin-left: 0 !important;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .reminder-popup {
    p {
      width: 292px;
    }
    .reminder-popup-content {
      max-width: 450px !important;
      .reminder-popup-buttons {
        font-size: 14px;
        line-height: 24px;
        width: 292px;
      }
    }
  }
  @media (orientation: landscape) {
    .reminder-popup {
      h3 {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
      }
      p {
        width: 292px;
      }
      .reminder-popup-content {
        max-width: 450px !important;
        padding: 20px !important;
        .reminder-popup-buttons {
          margin-top: 14px !important;
          width: 292px;
        }
      }
    }
  }
}
</style>
